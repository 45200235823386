<template>
	<div>
		<p>
			<span class="title">生成时间：</span><span>{{ order.createTime }}</span>
		</p>
		<p>
			<span class="title">工单编号：</span><span>{{ order.workOrderId }}</span>
		</p>
		<p>
			<span class="title">客户姓名：</span><span>{{ order.userName }}</span>
		</p>
		<template v-if="hasBed">
			<p>
				<span class="title">客户联系电话：</span><span>{{ order.userPhone }}</span>
			</p>
			<p>
				<span class="title">老人能力评估：</span>
				<span>{{ order.elderlyAbility }}</span>
			</p>
			<p>
				<span class="title">老人类型：</span>
				<span>{{ order.elderlyType }}</span>
			</p>
			<p>
				<span class="title">关联居家养老床位：</span><span>{{ order.bedUserName }}</span>
			</p>
			<p>
				<span class="title">服务机构：</span><span>{{ order.serviceName ? order.serviceName : '--' }}</span>
			</p>
		</template>
		<p>
			<span class="title">客户详细地址：</span><span>{{ order.address }}</span>
		</p>
		<p>
			<span class="title">服务项目：</span><span>{{ order.serviceProject }}</span>
		</p>
		<p>
			<span class="title">数量：</span><span>{{ order.quantity }}</span>
		</p>
		<p>
			<span class="title">执行价格：</span><span>{{ order.exercisePrice === '面议' ? '面议' : `${order.exercisePrice}元` }}</span>
		</p>
		<p>
			<span class="title">预约时间：</span><span>{{ order.apptTime }}</span>
		</p>
		<p v-if="order.staffName">
			<span class="title">服务人员姓名：</span><span>{{ order.staffName }}</span>
		</p>
		<p v-if="order.staffPhone">
			<span class="title">服务人员电话：</span><span>{{ order.staffPhone }}</span>
		</p>

		<p>
			<span class="title">关联订单编号：</span><span>{{ order.associatedOrderNumber }}</span>
		</p>
		<p>
			<span class="title">工单备注</span><span>{{ order.workOrderMessage }}</span>
		</p>
		<p v-if="hasBed">
			<span class="title">附件内容：</span>
			<template v-if="order.sfileAddress">
				<el-tooltip class="item" effect="dark" content="点击下载" placement="top-end">
					<el-link :underline="false" type="primary" @click="downFile(order.sfileAddress)">查看附件</el-link>
				</el-tooltip>
			</template>
			<span v-else>--</span>
		</p>
	</div>
</template>

<script>
import { getWorkOrderDetails } from '@/api/workManage'
import { ELDERLYABILITY, ELDERLYTYPE } from '@/data/dataDictionary'
import { downloadFile } from '@/utils/index'
export default {
	// 工单详情
	name: 'workDetailInfo',
	props: {
		orderId: {
			type: String,
			default: '',
		},
		orderServiceOgr: {
			type: String,
			default: null
		}
	},
	watch: {
		$route: {
			handler(val) {
				this.hasBed = val.name === 'serveDetail'
			},
			deep: true,
			immediate: true,
		},
	},
	data() {
		return {
			ELDERLYABILITY,
			ELDERLYTYPE,
			hasBed: true,
			order: {
				createTime: '',
				buyerName: '',
				buyerPhone: '',
				comboName: '',
				homeAdder: '',
				orderId: '',
				staffAdder: '',
				staffName: '',
				staffPhone: '',
				statusStr: '',
				bedUserName: '',
				fileAddress: '',
				elderlyAbility: '',
				elderlyType: '',
				associatedOrderNumber: '',
			},
		}
	},
	mounted() {
		this.getInfo()
	},
	methods: {
		async getInfo() {
			const params = { workOrderId: this.orderId }
			const res = await getWorkOrderDetails(params)
			if(!res.data.serviceName) res.data.serviceName = this.orderServiceOgr
			this.order = res.data
		},
		downFile(url) {
			const href = url
			downloadFile(href, href)
		},
	},
}
</script>

<style lang="scss" scoped>
p {
	color: #999999;
	padding: 0 60px;
}
.title {
	display: inline-block;
	width: 15%;
	color: #333333;
	min-width: 160px;
	// text-align: right;
}
</style>
